export default [
  {
    path: '/plantillas/list',
    name: 'plantillas-list',
    component: () => import('@/views/diseno/plantillas/PlantillasList.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Plantillas',
      breadcrumb: [
        {
          text: 'Plantillas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/docs/list',
    name: 'docs-list',
    component: () => import('@/views/diseno/docs/DocsList.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Docs',
      breadcrumb: [
        {
          text: 'Docs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/docs/generar',
    name: 'docs-new',
    component: () => import('@/views/diseno/docs/DocsEdit.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Generar Doc',
      breadcrumb: [
        {
          text: 'Generar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/plantillas/edit/:id',
    name: 'plantilla-edit',
    component: () => import('@/views/diseno/plantillas/PlantillaEdit.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Editar Plantilla',
      breadcrumb: [
        {
          text: 'Lista de Plantillas',
          active: false,
          to: '/plantillas/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
