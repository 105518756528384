export const messageAlerts = {
  errorAlert: {
    status: true,
    title: 'Error al realizar la operación',
    variant: 'danger',
    icon: 'AlertCircle',
  },
  a200Alert: {
    status: true,
    title: 'Operación realizada correctamente',
    variant: 'success',
    icon: 'CheckIcon',
  },
}

export function customAlert(status, title, variant, icon) {
  return {
    status,
    title,
    variant,
    icon,
  }
}
