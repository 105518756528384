import axios from 'axios'
import router from '@/router/index'
import auth from './auth'
import store from '@/store'

export default {
  setHeaders: async () => {
    const token = window.localStorage.getItem('accessToken')
    const token_type = window.localStorage.getItem('tokenType')

    axios.interceptors.response.use(response => response, error => {
      if (!error.response.config.url.includes('logout')) {
        if (error.response.data.toString().includes('Route [login] not defined')) {
          auth.clearLoggedUser()
          store.commit('SET_USER', null)
          router.push({ name: 'login' })
        }
        if (error.response.status === 401) {
          auth.clearLoggedUser()
          store.commit('SET_USER', null)
          router.push({ name: 'login' })
        }
      }

      return Promise.reject(error)
    })

    axios.defaults.headers = {
      Authorization: `${token_type} ${token}`,
    }
  },
}
