export default [
    {
      path: '/tareas',
      name: 'tareas-list',
      component: () => import('@/views/work/tareas/TareasList.vue'),
      meta: {
        access: ['admin'],
        pageTitle: 'Tareas',
        breadcrumb: [
          {
            text: 'Tareas-list',
            active: true,
          },
        ],
      },
    },
  ]
  