export default [
  {
    path: '/partes-trabajo',
    name: 'partes-trabajo',
    component: () => import('@/views/work/trabajo/PartesTrabajo.vue'),
    meta: {
      access: ['admin', 'operario'],
      pageTitle: 'Partes trabajo',
      breadcrumb: [
        {
          text: 'Partes trabajo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trabajo-edit/:id',
    name: 'trabajo-edit',
    component: () => import('@/views/work/trabajo/TrabajoEdit.vue'),
    meta: {
      access: ['admin', 'operario'],
      pageTitle: 'Editar trabajo',
      breadcrumb: [
        {
          text: 'Lista de Trabajos',
          active: false,
          to: '/partes-trabajo',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/zonas',
    name: 'zonas',
    component: () => import('@/views/work/zonas/ZonasList.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Zonas',
    },
  },
]