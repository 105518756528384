class User {
  constructor({
    id, fk_role, name, phone_number, departamentos, email, email_verified_at, status, remember_token, created_at, updated_at, accessToken, token_type,
  } = {}) {
    this.id = id
    this.fk_role = fk_role
    this.name = name
    this.email = email
    this.email_verified_at = email_verified_at
    this.status = status
    this.remember_token = remember_token
    this.created_at = created_at
    this.updated_at = updated_at
    this.accessToken = accessToken
    this.token_type = token_type
    this.departamentos = departamentos
    this.phone_number = phone_number
  }
}

export default User
