import moment from 'moment'

export default {
  methods: {
    formatDate(date, dateTime, newFormat) {
      if (date || dateTime) {
        moment.locale('es')
        const fecha = date
          ? moment(date).format('DD-MM-YYYY')
          : moment(dateTime).format('DD-MM-YYYY H:mm')
        return fecha
      }
      if (newFormat) return moment(date).format(newFormat)
    },
  },
}
