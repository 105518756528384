import auth from '@/auth/auth'
import { messageAlerts } from '@/middlewares/responseHandler'

export default {
  state: {
    user: null,
    loading: false,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async login({ commit }, payload) {
      commit('SET_LOADING', true)
      try {
        const user = await auth.login(payload)
        commit('SET_USER', user)
        commit('app/SET_ALERT', messageAlerts.a200Alert)
        return true
      } catch {
        commit('app/SET_ALERT', messageAlerts.errorAlert)
        return false
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async autologin({ commit }) {
      commit('SET_LOADING', true)
      try {
        const user = auth.getUserFromLocalStorage()
        commit('SET_USER', user)
        commit('app/SET_ALERT', messageAlerts.a200Alert)
        return true
      } catch {
        commit('app/SET_ALERT', messageAlerts.errorAlert)
        return false
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async logout({ commit }) {
      commit('SET_LOADING', true)
      try {
        await auth.logout()
        commit('SET_USER', null)
        commit('app/SET_ALERT', messageAlerts.a200Alert)
        return true
      } catch {
        commit('app/SET_ALERT', messageAlerts.errorAlert)
        return false
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.user != null
    },
    isRememberMe() {
      return auth.isRememberMe()
    },
  },
}
