import Vue from 'vue'
import VueRouter from 'vue-router'
import accessControl from '@/middlewares/accessControl'

import auth from './modules/auth'
import user from './modules/user'
import pages from './modules/pages'
import diseno from './modules/diseno'
import almacen from './modules/almacen'
import work from './modules/work'
import mantenimiento from './modules/mantenimiento'
import tarea from './modules/tarea'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...work,
    ...auth,
    ...user,
    ...pages,
    ...diseno,
    ...almacen,
    ...mantenimiento,
    ...tarea,
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(accessControl.manageRouter)

export default router
