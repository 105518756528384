export default [
    {
      path: '/mantenimientos',
      name: 'mantenimientos',
      component: () => import('@/views/mantenimientos/MantenimientosList.vue'),
      meta: {
        access: ['admin', 'operario'],
        pageTitle: 'Mantenimientos',
        breadcrumb: [
          {
            text: 'mantenimientos',
            active: true,
          },
        ],
      },
    }
]