import axios from 'axios'
import axiosConfig from '@/auth/axios'
import { AUTH_API } from '@/api/v1'
import User from '@/models/User'

function setUserLocalStorage(access_token, token_type, remember_me, user) {
  window.localStorage.setItem('accessToken', access_token)
  window.localStorage.setItem('tokenType', token_type)
  window.localStorage.setItem('rememberMe', remember_me)
  window.localStorage.setItem('userData', JSON.stringify(user))
}

function removeUserLocalStorage() {
  window.localStorage.removeItem('accessToken')
  window.localStorage.removeItem('tokenType')
  window.localStorage.removeItem('userData')
  window.localStorage.removeItem('rememberMe')
}

export default {
  login: async data => {
    const res = await axios.post(`${AUTH_API}/login`, {
      email: data.email,
      password: data.password,
    })
      .catch(e => e)
    const user = new User(res.data)
    setUserLocalStorage(res.data.accessToken, res.data.token_type, data.remember_me, user)
    axiosConfig.setHeaders()

    return user
  },
  logout: async () => {
    await axios.get(`${AUTH_API}/logout`)
      .then(() => {
        removeUserLocalStorage()
      })
      .catch(e => {
        console.error(e)
        removeUserLocalStorage()
      })
  },
  clearLoggedUser: async () => {
    removeUserLocalStorage()
  },
  isRememberMe: () => {
    const value = window.localStorage.getItem('rememberMe')
    return value === 'true'
  },
  getUserFromLocalStorage: () => new User(JSON.parse(window.localStorage.getItem('userData'))),
  getUserRole: () => JSON.parse(window.localStorage.getItem('userData')) ?? { role: 'user' },
}
