export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      access: ['admin', 'operario'],
      pageTitle: 'Home',
    },
  },
  {
    path: '/configuracion',
    name: 'configuracion',
    component: () => import('@/views/system/configuracion/Configuracion.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Configuracion',
      breadcrumb: [
        {
          text: 'Configuracion',
          active: true,
        },
      ],
    },
  },
  {
    path: '/error-403',
    name: 'error-403',
    component: () => import('@/views/error/Error403.vue'),
    meta: {
      access: ['public'],
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      access: ['public'],
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/departamentos/list',
    name: 'departamentos-list',
    component: () => import('@/views/system/departamento/DepartamentoList.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Departamentos',
      breadcrumb: [
        {
          text: 'Departamentos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/empleados/list',
    name: 'empleados-list',
    component: () => import('@/views/system/empleado/EmpleadoList.vue'),
    meta: {
      access: ['admin', 'operario'],
      pageTitle: 'Empleados',
      breadcrumb: [
        {
          text: 'Empleados',
          active: true,
        },
      ],
    },
  },
  {
    path: '/empleados/edit/:id',
    name: 'empleado-edit',
    component: () => import('@/views/system/empleado/EmpleadoEdit.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Editar Empleado',
      breadcrumb: [
        {
          text: 'Lista de Empleados',
          active: false,
          to: '/empleados/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/proveedores/list',
    name: 'proveedores-list',
    component: () => import('@/views/system/proveedor/ProveedorList.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Proveedores',
      breadcrumb: [
        {
          text: 'Proveedores',
          active: true,
        },
      ],
    },
  },
  {
    path: '/proveedores/edit/:id',
    name: 'proveedor-edit',
    component: () => import('@/views/system/proveedor/ProveedorEdit.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Editar Proveedor',
      breadcrumb: [
        {
          text: 'Lista de Proveedores',
          active: false,
          to: '/proveedores/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
