export default [
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/system/user/UsersList.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Usuarios',
      breadcrumb: [
        {
          text: 'Usuarios',
          active: true,
        },
      ],
    },
  },
]
